
import {
  IonApp,
  IonRouterOutlet,
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
  IonTitle,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonHeader,
    IonContent,
    IonFooter,
    IonToolbar,
    IonTitle,
  },
});
